@media screen and (orientation:portrait) {
    .camera{
        position: relative;
        height:100%;
    }
    .focus{
        .close{
            position:absolute;
            bottom:0px;
            right:0px;
            width: 30px;
            z-index: 999;
        }
       
    }
    .focus_text{
        position: relative;
    top: 50%;
    writing-mode: vertical-rl;
        transform: translateY(-50%);
        font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    
    color: #FFFFFF;
    // background: #EC6466;
    // box-shadow: 0px 4px 20px #0000001a;
    
    padding: 7px;
    /* margin-bottom: auto; */
    /* margin-top: auto; */
    /* margin-top: 221%; */
    }
    .top_name{
        position:absolute;
        top:50%;
        right:0;
        z-index:99;
        transform: translateY(-50%);
        height: 50%;
        // transform: rotate(90deg);
        border-radius: 10px 0px 0px 10px;
    }
    .bottom_name{
        position:absolute;
        top:50%;
        left:0;
        z-index:99;
        transform: translateY(-50%);
        height: 50%;
        // transform: rotate(90deg);
        border-radius: 0px 10px 10px 0px;;
    }
    .stencil{
        width: 100%;
        height: 37%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: top left;
        z-index: 99;
        display: inline-block;
        transform: rotate(90deg) translate(-50%,-50%);
    }
    .video_camera{
        position: absolute;
        // transform: rotate(90deg);
        // transform-origin: bottom left;
        width: 100vw;
        height: 76vh;
        // margin-top: -100vw;
        object-fit: cover;
        visibility: visible;
    }
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // object-fit: cover;
        // height: 100vh;
        // width: 100%;
    
    
    .retake_modal{
        margin:10px 20px;
        span{
            font-weight: bold;
            font-family: 'Lato';
            text-align: center;
         
        }
    }
    .yes_no_btn{
        display: flex;
        margin-top: 20px;
        justify-content: space-around;
        width:100%;
      
        button{
            border:none;
            outline: none;
            color:white;
            padding:10px 25px;
            border-radius: 10px;
            font-family: 'Lato';
            text-align: center;
            font-size: 13px;
        }
        :first-child{
    
            background-color: #e42125;
        }
        :last-child{
            background-color: grey;
        }
    }
   
 }
@media screen and (orientation:landscape) {
    .camera{
        position: relative;
    }
   
    .focus{
        .close{
            position:absolute;
            top:0px;
            right:0px;
            width: 30px;
            z-index: 999;
        }
        .focus_text{
            font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    // background: #EC6466;
    box-shadow: 0px 4px 20px #0000001a;
    
    padding: 7px;
        display: flex;
        justify-content: center;
        }
        .top_name{
            position:absolute;
            top:0px;
            left:50%;
            transform: translateX(-50%);
            width: 50%;
            border-radius: 0px 0px 10px 10px;
        }
        .bottom_name{
            width: 50%;
            position:absolute;
            bottom:0px;
            left:50%;
            transform: translateX(-50%);
            border-radius: 10px 10px 0px 0px;
        }
        .stencil{
    width:85%;
    height: 70%;
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        }
    }
    .video_camera{
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        height: 100vh;
        width: 100%;
    }
    
    .retake_modal{
        margin:10px 20px;
        span{
            font-weight: bold;
            font-family: 'Lato';
            text-align: center;
         
        }
    }
    .yes_no_btn{
        display: flex;
        margin-top: 20px;
        justify-content: space-around;
        width:100%;
      
        button{
            border:none;
            outline: none;
            color:white;
            padding:10px 25px;
            border-radius: 10px;
            font-family: 'Lato';
            text-align: center;
            font-size: 13px;
        }
        :first-child{
    
            background-color: #e42125;
        }
        :last-child{
            background-color: grey;
        }
    }
    
    
}

.left{
    width:20%;
    position:absolute;
    left:0px;
    top:50%;
    transform: translateY(-50%) scaleX(-1);
}

.right{
    width:20%;
    position:absolute;
    right:0px;
    top:50%;
    transform: translateY(-50%);
}

.top{
    transform:  translateX(-50%) rotate(270deg) ;
    position:absolute;
    top:0px;
    left:50%;
    width:20%;
}

.bottom{
    transform:  translateX(-50%) rotate(90deg);
    position:absolute;
    bottom:0px;
    left:50%;
    width:20%;

}





