.tnCContainer {
  margin: 20px ;
  .heading {
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    margin: 10px;
    line-height: 1.6;
    text-align: justify;
  }
  button {
    outline: none;
    display: block;
    padding: 15px;
    width: 100%;
    margin: 20px auto;
    border-radius: 4px;
    background-color: #EC6466;
    font-style: normal;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    z-index: 1;
    font-family: "Lato";
    border: unset;
    transform: translate3d(0, 0, 0);
  }
}
