.warning_message {
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    /* line-height: 0.5; */
}

.rotate_screen {
    width: 100%;
    max-height: 70vh;
}

.rotate_txt {
    font-size: 1.5rem;
    font-family: 'Lato', sans-serif;
    text-align: center;
    text-transform: uppercase;
    width: 100vw;
    padding: 10px;
}