.success_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 10px;
    margin: 20px;
    height: 100vh;
}

#thankyou_txt {
    font-family: 'Lato';
    text-align: center;
}

#success_para {
    font-family: 'Lato';
    text-align: center;
}

.footer-container {
    position: fixed;
    bottom: 0;
    color: #ffffff;
    background-color: #000000;
    padding: 10px;
    font-family: 'Lato';
    font-weight: 400;
}

a {
    text-decoration: none;
}