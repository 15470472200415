.upload_container {
  display: flex;
  display: -webkit-flexbox;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-top: 20px;
  // gap: 16px;

  // width: 343px;
  height: 90px;
  /* White Color */

  background: #ffffff;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.image {
  display: flex;
  width:70%;
  .upload_img {
    border-radius: 10px;
    height: 60px;
    width: 60px;
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    margin-left: 20px;
    span {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height */

      /* Black Color / 100 */

      color: #242424;
    }
    button {
      padding: 6px 8px;
      // background: #888888;
      border-radius: 4px;
      border: none;
      outline: none;
      font-family: "Lato";
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: #ffffff;
    }
  }
}

.submitBtn {
  outline: none;
  display: block;
  padding: 12.5px;
  width: 100%;
  margin: 20px auto;
  background-color: #EC6466;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  span{
    font-family: 'Lato';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-align: center;

/* White Color */

color: #FFFFFF;
  }
}

.detected{
 display: flex;
 justify-content: center;
 flex-direction: column;
 align-items: center;
 img{
  width:25px;
  height:25px
 }
}

.upload_text{
  margin-top: 8px;
  font-family: 'Lato';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 12px;
/* identical to box height */


/* Brand Color / 60 */
}

.angle_name{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  
  
  /* Black Color/90 */
  
  color: #3A3A3A;
}