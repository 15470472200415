.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999999;
}

.modalContainer {
  width: 300px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.promptModal {
  width: 350px; // Increased width
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  font-size: 14px; // Smaller font size
}


.modalRotate .modalContainer {
  transform: rotate(90deg) translate(-50%, -50%);
  transform-origin: top left;
}

.title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  position: absolute;
  right: 10px;
  top: 10px;

  img {
    width: 25px;
  }
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.body {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;

  img {
    height: 200px;
    width: 90%;
  }

  h2 {
    width: 60%;
    text-align: center;
  }

  button {
    padding: 0px 25px;
    height: 45px;
    border: none;
    background-color: grey;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
}
