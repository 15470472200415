


  .main_container{
    display: grid;
    grid-template-columns: 12vw 76vw 12vw;
    width: 100%;
    height: 100%;
    text-align: center;
  
  }
  
  .center_content{
      flex-grow: 1;
      background-color: rgb(255, 255, 255);
      display: block;
      max-width: 100%;
  }
