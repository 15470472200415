@import "./Assets/SCSS/Pages/Home.scss";
@import "./Assets/SCSS/Pages/Portrait.scss";
@import "./Assets/SCSS/Pages/Landscape.scss";
@import "./Assets/SCSS/Pages/RightContent.scss";
@import "./Assets/SCSS/Pages/LeftContent.scss";
@import "./Assets/SCSS/Pages/Camera.scss";
@import "./Assets/SCSS/Pages/Upload.scss";
@import "./Assets/SCSS/Pages/TermCondition.scss";
@import "./Assets/SCSS/Pages/Modal.scss";
@import "./Assets/SCSS/Pages/Thank.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato";
}


.container {
  min-height: 100vh;
  position: relative;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #e42125;
  width: 90px;
  height: 90px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// .loader {
//   width: 10px;
//   height: 10px;
//   border-radius: 50%;
//   display: block;
//   margin:15px auto;
//   position: relative;
//   color: #e42125;
//   left: -100px;
//   box-sizing: border-box;
//   animation: shadowRolling 3s linear infinite;
// }

// @keyframes shadowRolling {
//   0% {
//     box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
//   }
//   12% {
//     box-shadow: 100px 0 #e42125, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
//   }
//   25% {
//     box-shadow: 110px 0 #e42125, 100px 0 #e42125, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
//   }
//   36% {
//     box-shadow: 120px 0 #e42125, 110px 0 #e42125, 100px 0 #e42125, 0px 0 rgba(255, 255, 255, 0);
//   }
//   50% {
//     box-shadow: 130px 0 #e42125, 120px 0 #e42125, 110px 0 #e42125, 100px 0 #e42125;
//   }
//   62% {
//     box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 #e42125, 120px 0 #e42125, 110px 0 #e42125;
//   }
//   75% {
//     box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 #e42125, 120px 0 #e42125;
//   }
//   87% {
//     box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 #e42125;
//   }
//   100% {
//     box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
//   }
// }

// @-webkit-keyframes shadowRolling {
//   0% {
//      -webkit-box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
//   }
//   12% {
//      -webkit-box-shadow: 100px 0 #e42125, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
//   }
//   25% {
//      -webkit-box-shadow: 110px 0 #e42125, 100px 0 #e42125, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
//   }
//   36% {
//      -webkit-box-shadow: 120px 0 #e42125, 110px 0 #e42125, 100px 0 #e42125, 0px 0 rgba(255, 255, 255, 0);
//   }
//   50% {
//      -webkit-box-shadow: 130px 0 #e42125, 120px 0 #e42125, 110px 0 #e42125, 100px 0 #e42125;
//   }
//   62% {
//      -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 #e42125, 120px 0 #e42125, 110px 0 #e42125;
//   }
//   75% {
//      -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 #e42125, 120px 0 #e42125;
//   }
//   87% {
//      -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 #e42125;
//   }
//   100% {
//      -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
//   }
// }

.text {
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  font-family: "Lato";
  text-align: center;
}

.loaderGIF {
  width: 100vw;
  height: 100vh;
}
