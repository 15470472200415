.settings {
    // background-color: #242424;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    padding: 8px;
    height: 100%;
    transition: background-color 1s ease;
}

.full_screen , .theme_mode{
    height:44px
}

// .camera_icon{
//     height:65px
// }

@media screen and (orientation:portrait) {
    .settings {
        // background-color: #242424;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        padding: 8px;
        // height: 100vh;
        transition: background-color 1s ease;
    }
    
    .full_screen , .theme_mode{
        height:44px
    }
}


@media screen and (orientation:landscape) {
    .settings {
        // background-color: #242424;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 8px;
        height: 100vh;
        transition: background-color 1s ease;
    }
    
    .full_screen , .theme_mode{
        height:44px
    }
    
}
