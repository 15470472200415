.container{
    margin:1rem
}

.header_container{
    p{
        &:nth-child(1){
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        
        /* Black Color / 100 */
        
        color: #242424
        }
        &:nth-child(2){
            font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    
    /* Black Color / 80 */
    
    color: #505050
        }
    }
   
}

.box_container{
    margin-top: 1rem;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    gap:1rem;
border: 1px solid #FAD3D3;
box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1);
border-radius: 10px;
.car_details{
    display: grid;
    grid-template-columns: 50% 40%;
    column-gap: 1.5rem;
    row-gap: 1rem;
 }
 .grey_shade{
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;

/* Placeholder Color */

color: #B0B0B0;
 }
 .dark_shade{
    font-family: 'Lato';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* Black Color/90 */

color: #3A3A3A;
 }
}

.terms_checkbox{
    display: flex;
    input[type="checkbox"] {
        accent-color: #EC6466;
        }
        
    .tnC{
        margin-left:10.25px;
        font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
/* identical to box height */


/* Black Color / 100 */

color: #242424;
        span{
           color: dodgerblue
        }
    }
}

.inspection_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    button{
        min-width: 100%;
        color: #FFFFFF;
        background: #B0B0B0;
box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
border-radius: 4px;
padding: 10px;
font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: center;
width: 100%;
border: none;
outline: none;

/* White Color */

color: #FFFFFF;
    }
}


