

@media screen and (orientation:portrait) {
  .side_menu {
    overflow: scroll;
    height: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: row-reverse;
    transition: background-color 1s ease;
  }
  .side_menu::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .side_menu {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  
  
  .choose_angles {
    display: flex;
    // transform: rotate(90deg);
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding: 5px 1px;
    // gap: 10px;
    margin:8px;
    min-width: 20%;
    width: 100%;
    border-radius: 10px;
    .sidebar_img {
      transform: rotate(90deg);
      width: 50px;
      filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.15));
    }
    .text_color {
      font-family: "Lato";
      font-style: normal;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;
      writing-mode: vertical-lr;
  
      
    }
   
  }
  .position_text {
    position: absolute;
    left: 0px;
    writing-mode: vertical-lr;
    height: 100%;
    border-radius: 10px 0px 0px 10px;

    padding: 2px 0px;
    // width: 100%;
    background: rgba(233, 77, 81, 0.5);
    color: #ffffff;
  }

 
  // .choose_angles{
  //     // position: relative;
  //     border-radius: 10px;
  //     display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // padding: 5px 1px;
  // gap: 10px;
  // margin:18px 0;
  // width: 77px;
  // height: 56px;
  //     .text_color{
  //         // position: absolute;
  //         // bottom:0;
  //         background: rgba(233, 77, 81, 0.5);
  // border-radius: 0px 0px 10px 10px;
  //     }
  // }
  
  .positionImg {
    position: relative;
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .prediction{
    position: absolute;
    top:2px;
    right:2px;
    width:20px;
    height:20px
  }
}

@media screen and (orientation:landscape) {
  .side_menu {
    overflow: scroll;
    height: 100vh;
    padding: 0px 10px;
    transition: background-color 1s ease;
  }
  .side_menu::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .side_menu {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .choose_angles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 1px;
    gap: 10px;
    margin: 18px 0;
    min-height: 60px;
    width: 100%;
    border-radius: 10px;
    .sidebar_img {
      width: 60px;
      height: 26px;
      filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.15));
    }
    .text_color {
      font-family: "Lato";
      font-style: normal;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;
  
      
    }
    .position_text {
      position: absolute;
      bottom: 0px;
      padding: 2px 0px;
      width: 100%;
      background: rgba(233, 77, 81, 0.5);
      border-radius: 0px 0px 10px 10px;
      color: #ffffff;
    }
  }
  
  // .choose_angles{
  //     // position: relative;
  //     border-radius: 10px;
  //     display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // padding: 5px 1px;
  // gap: 10px;
  // margin:18px 0;
  // width: 77px;
  // height: 56px;
  //     .text_color{
  //         // position: absolute;
  //         // bottom:0;
  //         background: rgba(233, 77, 81, 0.5);
  // border-radius: 0px 0px 10px 10px;
  //     }
  // }
  
  .positionImg {
    position: relative;
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  
  .prediction{
    position: absolute;
    top:2px;
    right:2px;
    width:20px;
    height:20px
  }
}